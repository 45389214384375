import { Injectable } from '@angular/core'
import * as AWS from 'aws-sdk'
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, ICognitoUserData, IAuthenticationDetailsData } from "amazon-cognito-identity-js"
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'

export enum AuthError
{
    PasswordResetRequired,
    NoUserSessionFound
}

export interface LoginResult
{
    user: CognitoUser
    newPasswordRequired: boolean
}

/**
 * Service used to authenticate a user. This differs from the logged in user
 * service in that this purely is for authentication and does not provide
 * any additional information about the user.
 */
@Injectable({
    providedIn: 'root'
})
export class UserAuthService
{
    private static _poolData: any ={
        UserPoolId: environment.userpoolID,
        ClientId: environment.clientId
    }

    public cognitoCredentials: AWS.CognitoIdentityCredentials

    private _userPool: CognitoUserPool


    /**
     * Gets the currently logged in cognito user.
     */
    private getCurrentUser(): CognitoUser
    {
        return this._userPool.getCurrentUser()
    }



    async getToken(): Promise<string | null>
    {
        const session = await this.getLoggedInUserSession()

        return session.getIdToken()?.getJwtToken()
    }

    /**
     * Gets the current user session, if one exists, or returns
     * null otherwise
     */
    async getLoggedInUserSession(): Promise<CognitoUserSession | null>
    {
        // First things first we need to check if there is a logged in cognito user
        // this will also take care of the case where the page is refreshed after
        // login.
        const cognitoUser = this.getCurrentUser()
        if (!cognitoUser)
        {
            return null
        }

        const session = await this.getUserSession(cognitoUser)
        if (!session.isValid())
        {
            return null
        }

        return session
    }

    private authenticateUser(username: string, password: string): Promise<LoginResult>
    {
        return new Promise((resolve, reject) =>
        {

            var authData: IAuthenticationDetailsData = {
                Username: username,
                Password: password
            }

            var authDetails = new AuthenticationDetails(authData)

            var userData: ICognitoUserData = {
                Username: username,
                Pool: this._userPool
            }

            var cognitoUser = new CognitoUser(userData)

            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) =>
                {
                    resolve({
                        user: cognitoUser,
                        newPasswordRequired: false
                    })
                },
                onFailure: (error: any) =>
                {
                    reject(error)
                },
                newPasswordRequired: (userAttributes, requiredAttributes) =>
                {
                    resolve({
                        user: cognitoUser,
                        newPasswordRequired: true
                    })
                }
            })
        });
    }

    private getUserSession(user: CognitoUser): Promise<CognitoUserSession>
    {
        return new Promise((resolve, reject) => {

            // Try to get the current user session
            user.getSession((err, session: CognitoUserSession) =>
            {
                if (err)
                {
                    reject(err)
                }

                resolve(session)
            });

        })
    }

    /**
     * Logs a cognito user in.
     *
     * @param username The user's username
     * @param password The user's password
     */
    async login(username: string, password: string): Promise<LoginResult>
    {
        const loginResult = await this.authenticateUser(username, password)

        if (!loginResult.newPasswordRequired)
        {
            const session = await this.getUserSession(loginResult.user)
        }


        return loginResult
    }

    /**
     * Completes the password challenge for a cognito user.
     * @param cognitoUser The cognito user.
     * @param newPassword The new password.
     */
    async completePasswordChallenge(
        loginResult: LoginResult,
        newPassword: string
    ): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            loginResult.user.completeNewPasswordChallenge(newPassword, null, {
                onSuccess: () =>
                {
                    resolve()
                },
                onFailure: (error) =>
                {
                    reject(error)
                }
            })
        })
    }

    /**
     * Allows the user to change their password.
     * @param username User's username.
     * @param oldPassword The old password.
     * @param newPassword The new password.
     */
    changePassword(
        username: string,
        oldPassword: string,
        newPassword: string): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            var userData: any = {
                Username: username,
                Pool: this._userPool
            }

            var cognitoUser = new CognitoUser(userData)

            cognitoUser.changePassword(oldPassword, newPassword, (err, result) =>
            {
                if (err != null)
                {
                    reject(err)
                    return
                }

                // Success!
                resolve()
            })
        })
    }

    /**
     * Begins the forgotten password flow for a user.
     * @param username User's username.
     */
    beginForgottenPasswordFlow(username: string): Promise<CognitoUser>
    {
        return new Promise((resolve, reject) =>
        {
            var userData: ICognitoUserData = {
                Username: username,
                Pool: this._userPool
            }

            var cognitoUser = new CognitoUser(userData)

            cognitoUser.forgotPassword({
                onSuccess: function (result)
                {
                    reject("unexpected success: " + result)
                },
                onFailure: function (err)
                {
                    reject(err)
                },
                inputVerificationCode: function ()
                {
                    // We're going to resolve on both success and this, as I
                    // believe that success doesn't actually occur from this
                    // AWS function
                    resolve(cognitoUser)
                }
            })
        })
    }

    /**
     * Completes the forgotten password flow for a user.
     * @param username User's username.
     * @param verificationCode Verification code sent to the user.
     * @param newPassword The new password.
     */
    finishForgottenPasswordFlow(username: string, verificationCode: string, newPassword: string): Promise<void>
    {
        var userData: ICognitoUserData = {
            Username: username,
            Pool: this._userPool
        }

        var cognitoUser = new CognitoUser(userData)

        return new Promise((resolve, reject) =>
        {

            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () =>
                {
                    resolve()
                },
                onFailure: (err) =>
                {
                    reject(err)
                }
            })

        })
    }

    signOut(state?: string)
    {
        this.getCurrentUser().signOut()
        this.router.navigate(['login'], {
            queryParams: {
                "state": state
            }
        })
    }

    /**
     * Constructs the UserAuthService.
     * Initializes the user pool, and sets up listeners for user events.
     *
     * @param router The Angular Router.
     */
    constructor(
        private router: Router,
        )
    {
        this._userPool = new CognitoUserPool(UserAuthService._poolData);
    }
}
