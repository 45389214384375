import { Component, OnInit } from '@angular/core';
import { DefaultService, Notification } from '../../../ops-aware-users-api';
import { UserAuthService } from '../../../services/userAuth/user-auth.service';
import { Router, RouterLink } from '@angular/router';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Observable, firstValueFrom, interval } from 'rxjs';
import { NotificationSnackbarComponent } from '../notification-snackbar/notification-snackbar.component';
import { getIconForNotificationType, getNotificationDescription, getNotificationTitle, NotificationType } from '../../../utilities/notifications/descriptions';
import { mergeMap } from 'rxjs/operators';
import { NgFor, DatePipe } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

interface NotificationTableEntry
{
    title: string;
    description: string;
    notification: Notification;
}

@Component({
    selector: 'app-toolbar-notifications',
    templateUrl: './toolbar-notifications.component.html',
    styleUrls: ['./toolbar-notifications.component.css'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule, MatBadgeModule, RouterLink, NgFor, DatePipe, MatSnackBarModule]
})
export class ToolbarNotificationsComponent implements OnInit
{

    constructor(private apiGateway: DefaultService,
        private router: Router,
        private snackBar: MatSnackBar) { }

    /**
     * A list of the notifications that the drop down menu will show.
     */
    notifications: NotificationTableEntry[] = [];

    /**
     * A count of the total number of notifications to be displayed
     */
    totalNotificationCount: number = 0;

    /**
     * A count of the total number of unseen notifications to be displayed
     */
    totalUnseenCount: number = 0;

    lastCheckTime: Date = null;

    ngOnInit()
    {
        this.reloadNotifications();

        interval(1000 * 30).subscribe(() => this.reloadNotifications());
    }

    getIconForNotification(notification: Notification): string
    {
        return getIconForNotificationType(notification.type);
    }

    async reloadNotifications()
    {
        const notificationCollection = await firstValueFrom(this.apiGateway.getNotifications(undefined,"6",undefined, "0"))

        this.notifications = notificationCollection.results.map((notification) =>
        {
            return {
                notification: notification,
                description: getNotificationDescription(notification, false),
                title: getNotificationTitle(notification)
            }
        });
        this.totalUnseenCount = notificationCollection.counts.unseen;

        let unseenNotifications = notificationCollection.results.filter((notification) => {
            return (!notification.hasBeenSeen) && (notification.timestamp > this.notifications[0].notification.timestamp);
        });

        if (unseenNotifications.length > 0)
        {
            this.snackBar.openFromComponent(NotificationSnackbarComponent,{
                duration: 1000 * 15,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                data: unseenNotifications
            });
        }

        this.lastCheckTime = new Date();
    }

    onNotificationClicked(entry: NotificationTableEntry)
    {
        switch (entry.notification.type)
        {
            case NotificationType.ExtremeOverload:
            case NotificationType.Overload:
            case NotificationType.LostMainsPower:
            case NotificationType.LowBattery:
            case NotificationType.LowImpedanceFault:
            case NotificationType.LowSignal:
            case NotificationType.MissedReport:
                this.router.navigate(["device", entry.notification.context.device.id]);
                break;
            case NotificationType.NewFirmwareRelease:
                this.router.navigate(["device"])
                break;
        }
    }

    markVisibleAsSeen()
    {
        var ids = this.notifications.filter((value) =>
        {
            return (!value.notification.hasBeenSeen);
        }).map((value) =>
        {
            return value.notification.id;
        })

        // Don't bother the server if there isn't anything to do
        if (ids.length <= 0)
        {
            return;
        }

        this.apiGateway.seeNotifications({notificationIDs: ids}).subscribe(() =>
        {
            this.totalUnseenCount -= ids.length;
        });
    }

}
