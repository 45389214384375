import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

    get webAppVersion(): string
    {
        return "1.7.0"
    }

    constructor() { }
}
