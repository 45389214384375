/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:06:21Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface APIKeySettings { 
    /**
     * Whether or not the API key is active. If set to false the key will be considered revoked and will not work to authenticate API calls.
     */
    active?: boolean;
    /**
     * A human-readable description of what the API key is for.
     */
    description?: string;
    /**
     * The name to give the API key that you added.
     */
    name?: string;
}
