/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:06:21Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes a user's association with a company
 */
export interface UserCompanyAssociation { 
    /**
     * The ID of the company the user is associated with.
     */
    companyID: number;
    /**
     * The name of the company the user is associated with.
     */
    companyName: string;
    /**
     * Whether or not the user is a \"key contact\" of the company it is being associated with
     */
    isKeyContact?: boolean;
    /**
     * The role the user has in the company they are associated with.
     */
    role: string;
}
