/**
 * dev-ops-aware-logs-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:09:01Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExportURL } from '../model/exportURL';
import { LogCollection } from '../model/logCollection';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://api.dev.ifd.dynamicsynergyiot.com/logs';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Exports a list of logs
     * 
     * @param fromTimestamp Probably start of log period
     * @param toTimestamp Probably end of log period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportLogs(fromTimestamp?: string, toTimestamp?: string, observe?: 'body', reportProgress?: boolean): Observable<ExportURL>;
    public exportLogs(fromTimestamp?: string, toTimestamp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportURL>>;
    public exportLogs(fromTimestamp?: string, toTimestamp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportURL>>;
    public exportLogs(fromTimestamp?: string, toTimestamp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromTimestamp !== undefined && fromTimestamp !== null) {
            queryParameters = queryParameters.set('from_timestamp', <any>fromTimestamp);
        }
        if (toTimestamp !== undefined && toTimestamp !== null) {
            queryParameters = queryParameters.set('to_timestamp', <any>toTimestamp);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ExportURL>(`${this.basePath}/logs/export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets available logs (I think? I wasn&#39;t there man!)
     * 
     * @param limit Max size (probably?)
     * @param fromTimestamp Beginning of timescale I guess
     * @param offset The offset of the log entry to get for paging purposes
     * @param toTimestamp end of the timescale most likely
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'body', reportProgress?: boolean): Observable<LogCollection>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogCollection>>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogCollection>>;
    public getLogs(limit?: string, fromTimestamp?: string, offset?: string, toTimestamp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (fromTimestamp !== undefined && fromTimestamp !== null) {
            queryParameters = queryParameters.set('from_timestamp', <any>fromTimestamp);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (toTimestamp !== undefined && toTimestamp !== null) {
            queryParameters = queryParameters.set('to_timestamp', <any>toTimestamp);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json '
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LogCollection>(`${this.basePath}/logs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
