/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:10:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The settings for a firmware image
 */
export interface FirmwareUpdateSettingsUpdate { 
    /**
     * Set the activation state of the image settings.
     */
    active?: boolean;
    /**
     * White list for which companies are allowed to see this image when it is in a \"staging\" state.
     */
    allowedStagingCompanies?: Array<number>;
    /**
     * White list for which company groups are allowed to see this image when it is in a \"staging\" state.
     */
    allowedStagingCompanyGroups?: Array<number>;
    /**
     * The reason the device was deactivated. This is only present if \"active\" is being changed to \"deactivated\"
     */
    deactivationReason?: string;
    /**
     * Set whether or not the image is in \"staging\". An image that is in staging will only be visible to companies that are in the image's \"staging whitelist\"
     */
    staging?: boolean;
}
