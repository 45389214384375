import { log } from 'console'
import { DeviceNotificationState, DeviceOverview } from "../../ops-aware-devices-api";
import { Notification } from "../../ops-aware-users-api";

export enum NotificationTitle
{
    overload = `Temperature Overload`,
    extremeOverload = `Extreme Temperature Overload`,
    lowSignal = `Low Signal`,
    lowBattery = `Low Battery`,
    lostMainsPower = `Lost Mains Power`,
    missingReport = `Missed Report`,
    lowImpedanceFault = `Low Impedance Fault`,
    NewFirmwareRelease = `New SignalPort Firmware`,
    NewAppRelease = `New Web Application Release`,
    NewAPIRelease = `New B2B API Release`
}

export enum NotificationType
{
    NewAppRelease =      "newAppRelease",
    ExtremeOverload =    "extremeOverload",
    LostMainsPower =     "lostMainsPower",
    LowBattery =         "lowBattery",
    LowImpedanceFault =  "lowImpedanceFault",
    LowSignal =          "lowSignal",
    MissedReport =       "missingReport",
    NewAPIRelease =      "newApiRelease",
    NewFirmwareRelease = "newFirmwareRelease",
    Overload =           "overload",
}

export function getTitleForNotificationStatus(status: DeviceNotificationState): string[]
{
    let notificationTitles: string[] = [];
    if (status.overload)
    {
        notificationTitles.push(NotificationTitle.overload);
    }

    if (status.extremeOverload)
    {
        notificationTitles.push(NotificationTitle.extremeOverload);
    }

    if (status.lowSignal)
    {
        notificationTitles.push(NotificationTitle.lowSignal);
    }

    if (status.lowBattery)
    {
        notificationTitles.push(NotificationTitle.lowBattery);
    }

    if (status.noMains)
    {
        notificationTitles.push(NotificationTitle.lostMainsPower);
    }

    if (status.missingReport)
    {
        notificationTitles.push(NotificationTitle.missingReport);
    }

    if (status.lowImpedanceFault)
    {
        notificationTitles.push(NotificationTitle.lowImpedanceFault);
    }

    return notificationTitles;
}

export function getIconForNotificationType(type: string): string
{
    switch (type)
        {
            case NotificationType.ExtremeOverload:
            case NotificationType.Overload:
                return "thermostat";
            case NotificationType.LostMainsPower:
                return "power_off";
            case NotificationType.LowBattery:
                return "battery_alert";
            case NotificationType.LowImpedanceFault:
                return "error";
            case NotificationType.LowSignal:
                return "signal_cellular_nodata";
            case NotificationType.MissedReport:
                return "description";
            case NotificationType.NewAPIRelease: case NotificationType.NewAppRelease: case NotificationType.NewFirmwareRelease:
                return "upgrade"
        }

        return "error";
}

export function getTitleForNotificationType(type: string)
{
    switch (type)
    {
        case NotificationType.Overload:
            return NotificationTitle.overload;

        case NotificationType.ExtremeOverload:
            return NotificationTitle.extremeOverload;

        case NotificationType.LowSignal:
            return NotificationTitle.lowSignal;

        case NotificationType.LowBattery:
            return NotificationTitle.lowBattery;

        case NotificationType.LostMainsPower:
            return NotificationTitle.lostMainsPower;

        case NotificationType.MissedReport:
            return NotificationTitle.missingReport;

        case NotificationType.LowImpedanceFault:
            return NotificationTitle.lowImpedanceFault;

        case NotificationType.NewAPIRelease:
            return NotificationTitle.NewAPIRelease;

        case NotificationType.NewAppRelease:
            return NotificationTitle.NewAppRelease;

        case NotificationType.NewFirmwareRelease:
            return NotificationTitle.NewFirmwareRelease;


    }
}

export function getNotificationTitle(notification: Notification): string
{
    return getTitleForNotificationType(notification.type);
}

export function formatDeviceSerial(notification: Notification, includeLink: boolean): string
{
    if (includeLink)
    {
        return `<a href="/device/${notification.context.device.id}">${notification.context.device.serial}</a>`;
    }

    return `${notification.context.device.serial}`;
}

export function getNotificationDescription(notification: Notification, includeLinks: boolean): string
{
    if (!notification.context)
    {

        return "Description unavailable";
    }

    switch (notification.type)
    {
        case NotificationType.Overload:
            return `${formatDeviceSerial(notification, includeLinks)} reported a temperature overload.`;
        case NotificationType.ExtremeOverload:
            return `${formatDeviceSerial(notification, includeLinks)} reported an extreme temperature overload.`;
        case NotificationType.LowSignal:
            return `${formatDeviceSerial(notification, includeLinks)} reported a low signal value.`;
        case NotificationType.LowBattery:
            return `${formatDeviceSerial(notification, includeLinks)} reported a low battery reading.`;
        case NotificationType.LostMainsPower:
            return `${formatDeviceSerial(notification, includeLinks)} lost mains power.`;
        case NotificationType.MissedReport:
            return `${formatDeviceSerial(notification, includeLinks)} missed a report.`;
        case NotificationType.LowImpedanceFault:
            return `${formatDeviceSerial(notification, includeLinks)} detected a low impedance fault.`;
        case NotificationType.NewAPIRelease:
            return `New B2B API Release`;
        case NotificationType.NewFirmwareRelease:

            let version: string = `v${notification.context.version.major}.${notification.context.version.minor}.${notification.context.version.build}`
            if (includeLinks && notification.context.releaseNotesURL)
            {
                version = `<a href="${notification.context.releaseNotesURL}" download>${version}</a>`;
            }

            if (notification.context.isPrerelease)
            {
                return `New <b>Prerelease</b> SignalPort Firmware Available for testing: ${version}`;
            }
            else
            {
                return `New SignalPort Firmware Released: ${version}`;
            }


        case NotificationType.NewAppRelease:
            return `New Web App Version Released`;
    }
}
