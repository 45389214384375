/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:10:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A raw temperature-based oil level measurement
 */
export interface RawTemperatureOilLevel { 
    deviceID: number;
    deviceSerial: string;
    peripheralHardwareID: string;
    peripheralID: number;
    reportTimestamp: number;
    temperatures: Array<number>;
    timestamp: number;
}
