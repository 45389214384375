/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:10:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Describes a users association with a company
 */
export interface UserCompanyAssociationInput { 
    /**
     * The ID of the company to associate the user with.
     */
    companyID: number;
    /**
     * The role to assign the user in the company.
     */
    role: string;
}
