/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:08:05Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Describes the state of notifications for a device
 */
export interface DeviceNotificationState { 
    extremeOverload?: number;
    lowBattery?: number;
    lowImpedanceFault?: number;
    lowSignal?: number;
    missingReport?: number;
    noMains?: number;
    overload?: number;
}
