/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-05-02T21:08:05Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A measurement of signal quality
 */
export interface SignalQuality { 
    /**
     * The SignalPort's cell modem's Reference Signal Received Power, which is a measurement of the average power received from a standard LTE reference signal. 
     */
    rsrp?: number;
    /**
     * The SignalPort's cell modem's Reference Signal Received Quality, which is a ratio of the carrier power to the interference power.
     */
    rsrq?: number;
    /**
     * The SignalPort's cell modem's Received Signal Strength Intensity. This is a measure of the all received power, both wanted and unwanted.
     */
    rssi?: number;
    serviceMode?: number;
    /**
     * The SignalPort's cell modem's Signal to Interference Noise Ratio, which is the ratio of the signal compared to interference  and noise.
     */
    sinr?: number;
    /**
     * The Unix timestamp of when the Signal Quality Measurement was taken.
     */
    timestamp: number;
}
