import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';
import { UserAuthService } from '../services/userAuth/user-auth.service'
import { Router } from '@angular/router'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: UserAuthService,
    private router: Router,

  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // Check if the noAuth header is present
    if (request.headers.has('noAuth'))
    {
        request = request.clone({
            headers: request.headers.delete('noAuth')
        })

        return next.handle(request)
    }

    // Check whether the user is logged in
    return from(this.authService.getToken()).pipe(
        mergeMap((token) => {
            if (!token)
            {
                this.router.navigate(['login'], {
                queryParams: {
                    "state": this.router.url
                }})
            }

            const authRequest = request.clone({
                headers: request.headers.set('Authorization', token)
            });

            return next.handle(authRequest)
        })
    )
  }
}
